@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.full-width-wrapper {
  background-color: $ds-grey-100;
  overflow: hidden;

  &--light {
    background-color: white;
  }
}

.container {
  position: relative;
  margin: 0 auto;
  max-width: 892px;
  padding: 112px 0;

  &--bg-green-centered {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: 0;
      right: -150px;

      width: 400px;
      height: 100%;

      background-image: url('/static/components/bottomCTA/bg-green-top.svg');
      background-repeat: no-repeat;
      background-size: contain;

      @include p-size-tablet {
        left: 60%;
        right: auto;
      }
    }

    &-full {
      &:after {
        left: auto;
        right: -200px;
        height: 120%;

        @include p-size-tablet {
          right: -100px;
        }
      }
    }
  }

  &--bg-green-side {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: -246px;
      right: -500px;

      width: 542px;
      height: 642px;
      background-image: url('/static/components/bottomCTA/bg-green-bottom.svg');
      background-repeat: no-repeat;
      background-size: contain;

      @include p-size-mobile {
        right: -250px;
      }
    }
  }

  &--bg-green-small {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: -310px;
      right: -252px;

      width: 542px;
      height: 642px;
      background-image: url('/static/components/bottomCTA/bg-green-bottom.svg');
      background-repeat: no-repeat;
      background-size: contain;

      @include p-size-mobile {
        right: -250px;
      }
    }
  }

  &--bg-red-centered {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: 0px;
      right: -90px;

      width: 400px;
      height: 100%;
      background-image: url('/static/components/bottomCTA/bg-red.svg');
      background-repeat: no-repeat;
      background-size: contain;
      transform: scale(2);
    }
  }

  &--bg-red-side {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: -80px;
      right: -450px;

      width: 400px;
      height: 100%;
      background-image: url('/static/components/bottomCTA/bg-red.svg');
      background-repeat: no-repeat;
      background-size: contain;
      transform: scale(2);

      @include p-size-mobile {
        right: -250px;
      }
    }
  }

  &--bg-red-small {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: -80px;
      right: -220px;

      width: 400px;
      height: 100%;
      background-image: url('/static/components/bottomCTA/bg-red.svg');
      background-repeat: no-repeat;
      background-size: contain;
      transform: scale(2);

      @include p-size-mobile {
        opacity: 0.3;
      }
    }
  }
}

.wrapper {
  position: relative;
  z-index: 1;
  max-width: 668px;

  @include p-size-desktop {
    margin: 0;
  }

  &--center {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &--padding {
    padding-left: 40px;
  }
}
