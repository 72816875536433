@import '@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    margin-top: 96px;
  }
}

.smallCTAContainer {
  > div {
    border-radius: 8px;
  }
  > div > div {
    padding: 40px 56px;
  }
}
